.results-body {
    display: flex !important;
    flex-wrap: wrap !important;
}

.results-body .card{
    flex: 0 1 24%;
    box-sizing: border-box;
    padding: 10px;
    margin-left: 10px;
    margin-top: 10px;

}

/* on a device that is 1095px or less */
@media (max-width: 1095px) {
    .results-body .card {
        flex: 1 2 30%;
    }
}

/* on a device that is 810px or less */
@media (max-width: 810px) {
    .results-body .card {
        flex: 1 2 45% !important;
    }
}


/* on a device that is 580px or less */
@media (max-width: 580px) {
    .results-body .card {
        flex: 1 2 50% !important;
    }
    .results-body .card:last-of-type{
        margin-bottom: 10px;
    }
}



