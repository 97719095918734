.listing-card {
    min-width: 23vw !important;
    max-width: 23vw !important;
}

@media only screen and (max-width: 700px) {
    .listing-card {
        width: 100% !important;
    }
  }

.outer-details
{
    display: flex;
    flex-wrap: wrap;
}

.outer-details .details-icon {
    margin-right: 5px;
    color: rgb(240, 114, 56);
}

.outer-details .details-text
{
    min-width: 50%;
    flex: 1 2 50%;
}

.title
{
    margin: auto 0;
}