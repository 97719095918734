
.nav-VJM {
    background-color: #131E3A;
    z-index: 10;
}
@media only screen and (max-width: 700px) {
    .nav-VJM {
        background-color: #131E3A;
        z-index: 10;
        justify-content: center;
    }
  }

.logo:hover  {
    text-decoration: none !important;
}