.custom-pop-header {
    background-color: #131e3a;
    color: white;
}

.map-width {
    max-width: 100% !important;
}

#mapId {
    width: 100%;
    height: 90vh;
    /* max-width: 100% !important; */
}