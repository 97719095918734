.search-results-label {
    font-weight: 600;
}

.search-results-count {
    font-weight: 600;
}

.search-results-text {
    color: #F07238;
    font-weight: 600;
}