App {
    /* text-align: center; */
    height: 5000px;
}

.bg-color {
    background-color: #009638;
}

.scrollTop {
    width: 3%;
    position: fixed;
    bottom: 20%;
    right: 30px;
    align-items: flex-end;
    height: 20px;
    justify-content:right;
    z-index: 100;
    cursor: pointer;
    animation: fadeIn 0.3s;
    transition: opacity 0.4s;
    opacity: 0.5;
}

.scrollTop:hover {
    opacity: 1;
}

@keyframes fadeIn {
    0% {
        opacity: 0;
    }

    100% {
        opacity: 0.5;
    }
}