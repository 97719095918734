.footerbody{

    display: flex; 
    justify-content: space-between;
    margin-bottom: 10px;

   
}

.footerbody a, .footerbody p, .copyright{
    color: white;

}

.footerbody p{

    margin: 0;
}