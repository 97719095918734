@media only screen and (max-width: 700px) {
    .autocomplete-mobile-width {
        width: 200 !important;
    }
  }

  .autocomplete-mobile-width {
    width: 300 !important;
}

#search-bar fieldset {
  border: none;
}

.highlight-search-bar {
  border: solid #151592;
}

.reset-search-bar {
  border: solid #151592;
}